.container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
}

.modal_box {
    width: 50vw;
    height: 90vh;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 24px;
    padding: 1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.modal_box p, h2 {
    margin: 0;
}

/* Pevná hlavička modalBox (obsahuje uživatelské info a select) */
.header {
    flex: 0 0 60px;
    /* fixní výška 80px */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Užitečné info o uživateli (email, registered_at) s fixní výškou */
.userInfo {
    display: flex;
    flex-direction: column;
}

.userInfo p {
    margin: 0;
    font-size: 1rem;
    color: #333;
}

/* Nadpis modalBox */
.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 600px;
  }
  

/* Select pro změnu stavu – zůstává v hlavičce */
.selectRow {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.selectInput {
    padding: 0.5rem 1rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin: 0;
}

/* Řádek s nadpisem Obchody a vyhledávacím polem */
.shopHeader {
    flex: 0 0 50px;
    /* fixní výška 50px */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 0.5rem 0;
}

.shopHeader h3 {
    margin: 0;
    font-size: 1.4rem;
}

.shopSearch {
    max-width: 400px;
    padding: 0.4rem 0.8rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 0.9rem;
    margin: 0;
}

/* TextBox s obchody – tato část bude růst a scrollovat podle obsahu */
.textBox {
    flex: 1 1 auto;
    border-radius: 16px;
    background-color: #f0f0f0;
    padding: 1rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    scrollbar-width: none;
}

/* Detail obchodu */
.shopDetail {
    margin-bottom: 1rem;
}

/* Adresa lze případně mírně odsazovat */
.address {
    /* margin-left: 1rem; */
}

/* Spodní část – tlačítka mají fixní výšku */
.btn_box {
    flex: 0 0 60px;
    /* fixní výška 60px */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

/* Tlačítka */
.btn {
    background-color: #dafd82;
    border: 2px solid #dafd82;
    border-radius: 24px;
    color: #000;
    cursor: pointer;
    font: inherit;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    padding: 12px;
    text-align: center;
    transition: all 0.4s;
}

.btn:hover,
.btn:focus {
    background-color: #000;
    border-color: #000;
    color: #fff;
}

.delete {
    background-color: #ffc4c4;
    border: 2px solid #ffc4c4;
}

/* Případně lze přizpůsobit i velikost textu pro userValue */
.userValue {
    font-size: 1rem;
    color: #555;
    margin-right: 1rem;
}

.nothing {
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
    height: 100%;
    transform: translateY(50%);
}